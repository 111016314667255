import React ,{Component} from 'react';
import Loader from "../../loader";
import Grid from '@material-ui/core/Grid';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import customStyle from "../../../Assets/CSS/stylesheet_UHS";
import Button from "@material-ui/core/Button";
import customeClasses from "./Eligibility.css";
import axios from 'axios';
import DateFnsUtils from '@date-io/date-fns';
import configurations from "../../../configurations";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import {MuiPickersUtilsProvider, KeyboardDatePicker,DatePicker} from '@material-ui/pickers';
import {connect} from "react-redux";
import {Modal} from "react-bootstrap";
import {TableContainer, Radio, Table, TableCell,Paper, TableBody, TableHead, TableRow, FormControl, TextField, RadioGroup, FormControlLabel } from '@material-ui/core';
import "./Add-ons/addOnsScreen.css"
import moment from 'moment';

const PurpleRadio = withStyles(
    customStyle.radioBtn
)(props => <Radio color="default" {...props} />);

const ACSMCheckbox = withStyles({
    root: {
        color: '#533278',
        "&$checked": {
            color: '#533278',
        },
        "&$disabled": {
            color: 'grey'
        }
    },
    checked: {},
    disabled: {
        color: 'grey'
    }
})(Checkbox);

const styles = props => (
    customStyle.chkEligiScreen
);

const CssTextField = withStyles(theme => ({
    root: {
      '& .MuiInput-root': {
      "&:hover:not($disabled):not($focused):not($error):before": {
       borderBottom: '2px solid #533278'
      },
     
    '&.MuiInput-underline.Mui-focused:after':{
      borderBottom: '2px solid #533278',
  },
    
   }
  },
  }))(TextField);
const WizardButton = withStyles(
    customStyle.viewNetwellBtn
)(Button);

const ProceedButton = withStyles(
    customStyle.proceedNetwellBtn
)(Button);
const ViewButton = withStyles(
    customStyle.viewNetwellBtn
)(Button);

const NextButton = withStyles(
    customStyle.NextButton
)(Button);


class DateSelection extends Component{
    constructor(props) {
        super(props);
        this.state = {
            headerData : [],
            addOnsBodyData : [],
            loaderShow : true,
            progress : 0,
            count : 0,
            tableData : null,
            healthTool : false,
            membersArr : [],
            popTable : null,
            moreInfoModal : false,
            check:false,
            minDate: new Date(),
            subID: JSON.parse(localStorage.getItem('CurrentLoginUser')).id,
            myDate: moment().add(1, 'days'),
            recurringDay: 1,
            selectedDateRecurringPayment: new Date(),
            radioSelected: "Today",
            saveddataHistory: null,
        }
    }

    componentDidMount=()=> {
        this.getPaymentMode()
        let subId = JSON.parse(localStorage.getItem('CurrentLoginUser')).id;
        axios.get(process.env.REACT_APP_BASE_URL + "/enrollment/saveCompletionStatus/" + subId + "/" + this.props.activeStep)
        .then((response) => {
          if (response && response.data.code === 200) console.log(response.data.code)
        })
        .catch((error) => console.log(error));

        axios.get(process.env.REACT_APP_BASE_URL+"/plan/getTotalAmount"+`/${subId}`).then((resp)=>{
            this.setState({loaderShow: false})
            if(resp.data.response){
                this.setState({tableData: resp.data.response})
                console.log("::::", resp.data.response)
            }
        })

        axios.get(process.env.REACT_APP_BASE_URL+"/plan/getMemberPlan"+`/${this.state.subID}/Core`).then((resp)=>{
            this.setState({loaderShow: false})
            if(resp.data.response){
                if(resp.data.response.paymentDay)
                this.setState({saveddataHistory: resp.data.response, myDate: resp.data.response.targetDate}, () => {
                    if(resp.data.response.targetDate && moment().isAfter(resp.data.response.targetDate)){
                        // if(Number(moment().format("D"))>=14){
                            this.setState({myDate:  moment().add(1, 'days'), minDate:  moment().add(1, 'days')})
                        // }else{
                            // this.setState({myDate:  moment().date(1).add((moment().date() > 14 ? 1 : 0), "months").add(14,"days")})
                        // }
                    }
                    if(resp.data.response.targetDate && moment().isSame(resp.data.response.targetDate)){
                        this.setState({myDate:  moment().add(1, 'days'), minDate:  moment().add(1, 'days')})
                        // if(Number(moment().format("D"))>=14){
                        //     this.setState({myDate:  moment().endOf('month').add(1, 'days')})
                        // }else{
                        //     this.setState({myDate:  moment().date(1).add((moment().date() > 14 ? 1 : 0), "months").add(14,"days")})
                        // }
                    }
                })
                else
                this.setState({saveddataHistory: resp.data.response,})
            }})
    }

    getPaymentMode = () => {
        fetch(`${configurations.baseUrl}/setuppayment/getPaymentDetails/${this.props.subId}`)
            .then((response) => response.json())
            .then((response) => {
                if (response.response) {
                    if (response.response.paymentType === "ACH") 
                        this.setState({ myDate: moment().add(5, 'days'), minDate: moment().add(5, 'days'), recurringDay: 5 })
                    else this.setState({ myDate: moment().add(1, 'days'), minDate: moment().add(1, 'days'), recurringDay: 1 })
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    handleBack = () =>{
        this.props.onClickjumpfive();
    }

    handleProceed = (e) =>{
        let obj = {
            "subId": this.state.subID,
            "effectiveDate":moment(this.state.myDate).format("YYYY-MM-DD"),
            "draftDate":moment(this.state.selectedDateRecurringPayment).format("YYYY-MM-DD"),
            "paymentDay":this.state.radioSelected
        }
        if(e.detail === 1)
        axios.post(process.env.REACT_APP_BASE_URL+"/plan/updateMemberPlan", obj).then((resp)=>{
             this.props.onClick();
        })
       
    }
    handleDateChange = (date,didMount) => {
        console.log("date---",date)
      this.setState({
          targetDate: date,
          myDate: date
      }
      , () =>{
            let panel = document.getElementById("date-picker-dialog");
            panel.addEventListener("onmouseleave", function() {
                document.getElementById("date-picker-dialog-label").style.paddingTop = "10px";
            });
        }
        );    
    }
    disableWeekends(date, clientId) {
        if(true){
        // if(date.getDate() === 1 ||  date.getDate() === 15  ){
            return false
        }else {
            return true
        }
    }

    render() {
        let futureDate = new Date(today);
        const today = new Date();
        const tomorrow = new Date(today);
        let futureTomarow = new Date(today);
        if(
            sessionStorage.getItem('CLIENT_ID') === '1002' )
            {
                if(new Date() < new Date("05/01/2021")){
                    if(new Date() < new Date(this.state.empEffectiveDate)){
                        let efectiveDate = new Date(this.state.empEffectiveDate);
                        let effectiveDay = new Date(this.state.empEffectiveDate).getDate();
                        if(effectiveDay === 1 || effectiveDay > 1){
                            tomorrow.setDate(1);
                            tomorrow.setMonth(new Date(efectiveDate).getMonth() + 1);
        
                            futureTomarow.setDate(1);
                            futureTomarow.setMonth(new Date(efectiveDate).getMonth() + 1);
                        }
        
                    } else {
                        tomorrow.setDate(1);
                        tomorrow.setMonth(4);
                        futureTomarow.setDate(1);
                        futureTomarow.setMonth(4)
                    }
                } else if(new Date().getDate() === 1 ||  new Date().getDate() > 1){
        
                    if(new Date() < new Date(this.state.empEffectiveDate)){
                        let efectiveDate = new Date(this.state.empEffectiveDate);
                        let effectiveDay = new Date(this.state.empEffectiveDate).getDate();
                        if(effectiveDay === 1 || effectiveDay > 1){
                            tomorrow.setDate(1);
                            tomorrow.setMonth(new Date(efectiveDate).getMonth() + 1);
                            futureTomarow.setDate(1);
                            futureTomarow.setMonth(new Date(efectiveDate).getMonth() + 1);
                        }
                    } else {
                        tomorrow.setDate(1);
                        tomorrow.setMonth(today.getMonth() + 1);
                        futureTomarow.setDate(1);
                        futureTomarow.setMonth(today.getMonth() + 1)
                    }
                }
                futureDate = futureTomarow.setDate(futureTomarow.getDate() + 30)
            } else if (this.state.clientId === '1004' || this.state.clientId === '1005' || this.state.clientId === '1006' || this.state.clientId === '1007'  || this.state.clientId === '1008' || this.state.clientId === '1010' || this.state.clientId === '1011' || this.state.clientId === '1012') {
                futureDate = new Date(this.state.minDate);
                tomorrow.setDate(tomorrow.getDate() + 1);
                if (futureDate.getDate() === 1) {
                    futureDate.setDate(1);
                    futureDate = futureDate.setDate(futureDate.getDate() + 30)
                } else {
                    futureDate.setDate(15);
                    futureDate = futureDate.setDate(futureDate.getDate() + 30)
                }
            }

        const { classes } = this.props;
        const maxDate = moment(this.state.minDate).add(29, 'days');
        const limitDate = moment('2025-01-01', 'YYYY-MM-DD');
        const finalMaxDate = moment.max(maxDate, limitDate);
        let currentScreen
             currentScreen = <Grid container spacing={2} row>
               
                <Grid item xs={12} sm={3}>
                    <div style={{textAlign : 'justify', width : '95%'}}>
                        Please select the effective start date that will be applicable for your plan and add-ons.
                    </div>   
                    <div style={customStyle.EnrollNew2DateMob}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                required
                                key={this.state.reEnroll ? this.state.reEnrollDate : this.state.myDate}
                                onBlur={()=>this.setState({birthDtFocus:true})}
                                onMouseOver={()=>this.setState({birthDt:true})}
                                onMouseLeave={()=>this.setState({birthDt:false})}
                                autoComplete='off'
                                margin="none"
                                id="date-picker-dialog"
                                label="Effective Start Date"
                                format="MM/dd/yyyy"
                                disabled={this.props.isChangeProgram || this.props.isHouseholdUpdate}
                                error={this.state.dateErr} //&&!this.state.todayDateValid
                                helperText={this.state.dateErr?'Enter valid date':''} //this.state.todayDateValid?'Date Required':
                                value={this.state.reEnroll ? this.state.reEnrollDate : this.state.myDate}
                                onFocus={e => e.target.blur()}
                                onCopy={this.handlerCopy}
                                onPaste={this.handlerCopy}
                                inputProps={{style: {fontSize:'18px',fontFamily: 'Roboto, Arial, Helvetica, sans-serif',paddingLeft:'11px',paddingRight:'10px',marginTop:'11px','&:focus':{ outline: 'none'},color: !this.state.birthDt?'#19191d':'#533278'}}}
                                InputLabelProps={{style:{paddingLeft:10,paddingRight:10,paddingTop:12,color: !this.state.birthDtFocus?'grey': this.state.birthDt?'#533278':'grey'}}}//|| !this.state.todayDateValid
                                onChange={this.handleDateChange.bind(this)}
                                variant="filled"
                                // onMouseEnter={this.handleHover}
                                TextFieldComponent={CssTextField}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                style={{width:'100%'}}
                                shouldDisableDate={(e) => this.disableWeekends(e, this.state.clientId)}
                                minDate={this.state.minDate}
                                maxDate={finalMaxDate}
                            />
                            <span id='bd' style={customStyle.EnrollNew2Span}></span>
                        </MuiPickersUtilsProvider>
                    </div>
                </Grid>
                <Grid item xs={12} sm={2} />
                <Grid item xs={12} sm={7}>
                    <div>
                    <div style={{textAlign : 'justify', width : '95%', marginBottom:20}}>
                    Also take note of the <b>Key Payment Dates</b> mentioned below and select when you would like your first payment to be processed.
                    </div> 
                    <div className="addOnTable reviewChoice" style={{width : '100%', overflowX : 'auto'}}>
                    {       
                        this.state.tableData && this.state.saveddataHistory && <CommonTable recurringDay={this.state.recurringDay} effectiveDate={this.state.myDate} saveddataHistory={this.state.saveddataHistory} tableData={this.state.tableData} radioSelected={(val) => this.setState({radioSelected: val})} selectedDateRecurringPayment={(val)=> this.setState({selectedDateRecurringPayment: val})}/>
                    }
                    </div>
                    <p style={{marginTop: 5}}>*The actual date may vary based on the number of days in the month</p>
                  
                    </div>
                </Grid>
            </Grid>
        

        return (
            <div style={{flexGrow: 1}}>
                {
                    this.state.loaderShow ? <Loader></Loader> : ''
                }
                <Grid container style={{ fontFamily : 'Roboto, Arial, Helvetica, sans-serif', fontSize : '14px'}}>
                   

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        {currentScreen}
                    </Grid>
                    <Grid item xs={12} sm={10} md={6} style={{marginTop : '25px'}}>
                        <Grid container spacing={3}>
                        

                            <Grid item xs={4} sm={3}>
                                <ProceedButton disabled={false}
                                               variant="contained" style={{width : '100%'}}
                                               onClick={this.handleProceed}>Done
                                </ProceedButton>
                                {/*style={{width: '104px', height: '40px'}}*/}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

           

            </div>
        );
    }
    handleAddOn(){
        if(!this.state.check)
        this.setState({check:true})
        else
        this.setState({check:false})
    }
}

const mapStateToProps = state => {
    return {
        subId: state.subId,
        email : state.email
    };
}


export default withStyles(styles)(connect(mapStateToProps)(DateSelection));



const CommonTable = (props) => {
    const [openCustomDatepopup, setCustomDatePopup] = React.useState(false);
    const [radioSelected, setRadioSelected] = React.useState("Today");
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [futureDate, setfutureDate] = React.useState(new Date());
    React.useEffect(() => {
  
        if(props.saveddataHistory && props.saveddataHistory.paymentDay){
            setRadioSelected(props.saveddataHistory.paymentDay);
            setSelectedDate(props.saveddataHistory.draftDate);
        }
    }, [])
    const answerChangeHandler = (value) =>{
        setRadioSelected(value);
        if(value === "Custom")
        setCustomDatePopup(true)
        else
        setSelectedDate(new Date())
    }
    const setIsPickerOpen = (value) =>{
        console.log("::::", value)
        setSelectedDate(value)
        setCustomDatePopup(false)
    }
    const setDateCustom = () =>{
        setCustomDatePopup(true);
        setRadioSelected("Custom")
    }
    React.useEffect(()=>{
        props.selectedDateRecurringPayment(selectedDate);
    }, [selectedDate]);
    React.useEffect(()=>{
        setSelectedDate(new Date());
    },[props.effectiveDate])
    React.useEffect(()=>{
        props.radioSelected(radioSelected);
    }, [radioSelected])
    return ( 
      <>
        <TableContainer component={Paper}>
          <Table  sx={{ minWidth: "100%", fontWeight: "Lato" }} aria-label="customized table">
            <TableHead>
              <TableRow style={{ backgroundColor: "#0e234d"}}>
                
                {props.tableData.header.map((data) => <TableCell style={{padding: 11, width:"auto"}}><b style={{color: "#fff"}}>{data}</b></TableCell>)}
                
                <TableCell style={style} align="left">
                <b style={{color: "#fff"}}>Date</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow style={{ borderBottom : '2px solid #420045',}}>
                <TableCell style={{ padding: 11,  background:"#f2f2f2" }} component="th" scope="row">
                <p style={{color: "#000", marginBottom: 0}}>{props.tableData.body[0].text}</p>
                </TableCell>
                <TableCell style={{ padding: 11,  background:"#f2f2f2" }} component="th" scope="row" align="left">
                <p style={{color: "#000", marginBottom: 0}}><b>{props.tableData.body[0].amount}</b></p>
                </TableCell>
                <TableCell style={{ padding: 11,  background:"#f2f2f2" }} align="left">
        
                <div>
                {/* <RadioGroup
                 
                  aria-label="days"
                  name="row-radio-buttons-group"
                  value={radioSelected}
                  onChange={(event) =>
                    answerChangeHandler(event.target.value)
                  }
                >
                  <FormControlLabel
                    value="Today"
                    control={<PurpleRadio />}
                    label="Date of application submission"
                  />
                  <div onClick={() => setDateCustom()}>
                    <FormControlLabel
                    value="Custom"
                    control={<PurpleRadio />}
                    label="Custom"
                  />
                  
                  <CalendarTodayIcon style={{fontSize: 18, marginTop: "-8px", cursor: "pointer"}}/>
                </div>
                </RadioGroup> */}
                <CalendarTodayIcon onClick={() => setDateCustom()} style={{fontSize: 18, marginTop: "-3px", cursor: "pointer"}} /> <span>Payment Date: <b>{moment(selectedDate).format("MMM DD, YYYY")}</b></span>
              
              </div>
            
                </TableCell>
              </TableRow>
  
              <TableRow style={{ borderBottom : '2px solid #420045', background: "#fff"}}>
                <TableCell className='dateSelection' style={{ padding: 11,  background:"#f2f2f2" }} component="th" scope="row">
                <p style={{color: "#000", marginBottom: 0}}>{props.tableData.body[1].text}</p>
                </TableCell>
                <TableCell  style={{ padding: 11,  background:"#f2f2f2" }}  component="th" scope="row" align="left">
                <p style={{color: "#000", marginBottom: 0}}><b>{props.tableData.body[1].amount}</b></p>
                </TableCell>
                <TableCell  style={{ padding: 11,  background:"#f2f2f2" }}  align="left">{moment(props.effectiveDate).subtract(props.recurringDay, 'days').format("Do")} of every month*</TableCell>
              </TableRow>
            
            </TableBody>
          </Table>
        </TableContainer>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    InputProps={{readOnly: false}}
                    onClick={(e) => setIsPickerOpen(e)}
                    onChange={(e) => setIsPickerOpen(e)}
                    value={selectedDate}
                    open={openCustomDatepopup}
                    minDate={moment(futureDate)}
                    maxDate={moment(props.effectiveDate).subtract(1, 'days')}
                    style={{display: "none"}}
                    onClose={() => setCustomDatePopup(false)} 
                    />
            </MuiPickersUtilsProvider>
      </>
    );
  };
  const style={
    padding: 11,
  }
  