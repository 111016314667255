import React, { Component } from 'react';
//import { Button } from '@material-ui/core';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import { TextField, Table, TableCell, Modal, TableBody, Radio, TableHead, TableContainer,  TableRow, Button, Box, Tooltip, Paper, Toolbar, Typography, IconButton, Badge, Grid  } from '@material-ui/core';
import Loader from '../../loader';
import { connect } from 'react-redux';
// import { } from 'react-bootstrap';
// import {Panel} from 'react-bootstrap';
import axios from "axios";
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import i18n from '../../../i18next';
import customStyle from '../../../Assets/CSS/stylesheet_UHS';
import moment from "moment";
import Cart from '../../../Assets/Images/cart.svg'
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardDatePicker,DatePicker} from '@material-ui/pickers';
import CommonDropFamily from './CommonPopup/CommonDropFamily';
import CommonDropDwn from './CommonPopup/CommonDropDwn';
import CommonTable from './CommonPopup/CommonTable'
import './Add-ons/addOnsScreen.css';



const useStylesBootstrap = makeStyles(theme => ({
  arrow: {
      color: '#fa6446',
  },
  tooltip: {
    backgroundColor: '#fa6446',
      border: '1px solid #dadde9',
      fontSize : '12px'
  },
}));

const useStylesBootstrap_otherQuote = makeStyles(theme => ({
    arrow: {
        color: '#4a4b57',
    },
    tooltip: {
        backgroundColor: '#4a4b57',
        border: '1px solid #dadde9',
        fontSize : '12px'
    },
}));


const StyledTableRow = withStyles({
    root: {
        backgroundColor: "#F4F4F4",
        '&.Mui-selected': {
            backgroundColor: "#08C289",
          },
          "&.Mui-selected.MuiTableRow-hover":{
            backgroundColor: "#08C289",
          },
    },
  })(TableRow);


const ProceedButton = withStyles(
    {
        root: {
            backgroundColor: '#0e234d',
            color: '#ffffff',
            fontWeight: "bold",
            '&:hover': {
                backgroundColor: '#420045',
                boxShadow: '0 4px 8px 0 #eae8db, 0 2px 4px 0 #eae8db',
            },
            '&:focus':{
                outline:'none'
            },
            '&:disabled': {
                backgroundColor: '#BDBDBD',
                color: '#ffffff'
            },
        },
    }

)(Button);

const styles = theme => ({
  table: {
    minWidth: 400,
  },
});




class PlanSelection extends Component {
  constructor(props) {
    super(props);
      this.state = {
        clientid: sessionStorage.getItem("CLIENT_ID"),
        subId:  JSON.parse(localStorage.getItem('CurrentLoginUser')).id,
        loaderShow: false,
        selected: 0,
        data: null,
        listOfCost:[],
        listOfEnrollCost:[],
        familyList: [],
        selectedFamily:null,
    };
  }

  componentDidMount() {
    this.setState({loaderShow: true})

    axios.get(process.env.REACT_APP_BASE_URL + "/enrollment/saveCompletionStatus/" + this.state.subId + "/" + this.props.activeStep)
    .then((response) => {
      if (response && response.data.code === 200) console.log(response.data.code)
    })
    .catch((error) => console.log(error));

    axios.get(process.env.REACT_APP_BASE_URL+"/plan/getInnovationPlanData/"+`${this.state.subId}/${this.state.clientid}`).then((res)=>{
        this.setState({data: res.data.response, 
          selectedFamily: res.data.response[0].categoryAmountList[0].category
        }, () => {
         
            let listCost= this.state.listOfCost;
            this.state.data.map((res) => {
              listCost.push(res.categoryAmountList[0].amount);
            });
        
            let listEnrollCost=[];
            this.state.data.map((res) => {
                listEnrollCost.push(res.enrollmentFeesList[0].amount);
              });

            let familyList =[];
            this.state.data.map((res)=>{
                familyList.push(res.categoryAmountList[0].category);
            })

          this.setState({listOfCost: listCost, listOfEnrollCost: listEnrollCost, familyList: familyList}, () =>{
              axios.get(process.env.REACT_APP_BASE_URL+"/plan/getMemberPlan"+`/${this.state.subId}/Core`).then((resp)=>{
                this.setState({loaderShow: false})
                if(resp.data.response){
                  let index=this.state.data.indexOf(this.state.data.filter(x => x.planId === resp.data.response.planCode)[0]);
                  let listOfCost = this.state.listOfCost;
                  let listOfEnrollCost = this.state.listOfEnrollCost;
                  let family = this.state.familyList;

                  let flagRemoveDepentent = this.state.data[index].categoryAmountList.find(x => x.category === resp.data.response.category) ? true :false;

                  family[index] = flagRemoveDepentent ? resp.data.response.category : this.state.data[index].categoryAmountList[0].category;
                  listOfCost[index] = flagRemoveDepentent ? resp.data.response.amount :  this.state.data[index].categoryAmountList[0].amount;
                  if(res.data.response.find(x=>x.planId===resp.data.response.planCode).enrollmentFeesList.find(v=>v.amount===resp.data.response.appFee)){
                      listOfEnrollCost[index] = resp.data.response.appFee;
                  }
                    this.setState({
                      selectedFamily: flagRemoveDepentent ? resp.data.response.category : this.state.data[index].categoryAmountList[0].category,
                      selected: index,
                      listOfCost: listOfCost,
                      familyList: family,
                      listOfEnrollCost:listOfEnrollCost,
                    })
                }
              })
            })
           
        })
    })

   }

  
    handleClick = (index) => {
      if(!this.props.reEnrollBySC)
        this.setState({selected: index});
    };
    SubmitPlanRequest = (e) =>{
      if (e.detail === 1){
        let obj = {
            subId: this.state.subId,
            planCode: this.state.data[this.state.selected].planId,
            amount: this.state.listOfCost[this.state.selected],
            appFee: this.state.listOfEnrollCost[this.state.selected],
            category: this.state.familyList[this.state.selected],
            planType:"Core"
        }
        this.setState({loaderShow: true})
        axios.post(process.env.REACT_APP_BASE_URL+"/plan/saveMemberPlan", obj).then((res)=>{
            if(res.data.response)
            this.props.onClick();
            this.setState({loaderShow: false})
        }).catch(err=>{
            console.log(err);
            this.setState({loaderShow: false})
        })
    }
    }

    render() {
    if(this.state.data)
    return (
      <div style={{margin: "-2%"}}>
        {this.state.loaderShow ? <Loader/> : ''}
              <Box sx={{ width: "100%"}}>
              <Paper sx={{ width: "100%", mb: 2 }}>
              <EnhancedTableToolbar
                    title={this.props.reEnrollBySC ? 
                      `Your current plan ${this.state.data[this.state.selected].planId} is selected for your reference and cannot be changed.`
                      : "Review the plans available, compare premiums and click the ADD button."}
                    planSelected={this.state.data[this.state.selected]}
                    costSelected={this.state.listOfCost[this.state.selected]}
                    enrollFeeSelected={this.state.listOfEnrollCost[this.state.selected]}
                    selectedFamily={this.state.selectedFamily}
                    reEnrollBySC={this.props.reEnrollBySC}
                />


                <TableContainer style={{height: "38vh"}}>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              // size={dense ? "small" : "medium"}
            >
              <TableBody>
                {this.state.data.map((row, index) => {
                  const isItemSelected = index === this.state.selected;
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <StyledTableRow
                      hover
                      className={this.props.reEnrollBySC ? 'disableSCcss': ""}
                      onClick={(event) => this.handleClick(index)}
                      role="radio"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.planInfo}
                      selected={isItemSelected}>
                      <TableCell padding="checkbox"  style={{paddingTop:0}}>
                        <Radio
                          checked={isItemSelected}
                          value={row.planInfo}
                          style={{
                            color: "#162242"
                          }}
                          name="radio-buttons"
                          className="radio-buttons"
                          inputProps={{ "aria-label": "A" }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        style={{paddingTop:0, paddingBottom: 0}}
                      >
                        Add <b>{row.planId}</b>
                      </TableCell>

                      {!this.props.reEnrollBySC && <TableCell align="left" style={{ width: "10%", paddingTop:0, paddingBottom: 0 }}>
                       
                          {this.state.listOfEnrollCost[index] !== undefined && <CommonDropDwn
                            value={row.enrollmentFeesList}
                            index={index}
                            disabled={this.props.reEnrollBySC}
                            selectedIndex={this.state.selected === index}
                            selectedEnrollFee={this.state.listOfEnrollCost[index]}
                            selectCost={(value, ind) => {
                              let list= this.state.listOfEnrollCost;
                              list[ind] = value;
                              this.setState({listOfEnrollCost: list})
                            }} 
                          />}
                    
                      </TableCell>}

                      <TableCell align="left" style={{ width: "10%", paddingTop:0, paddingBottom: 0  }}>
                        
                        {this.state.familyList[index] && <CommonDropFamily
                          value={row.categoryAmountList}
                          index={index}
                          disabled={this.props.reEnrollBySC}
                          selectedIndex={this.state.selected === index}
                          selectedFamily={this.state.familyList[index]}
                          selectCost={(value, ind) => {
                            let list = this.state.listOfCost;
                            let familyList = this.state.familyList;
                            let amount = row.categoryAmountList.filter(
                              (x) => x.category === value
                            )[0].amount;
                            familyList[ind] = value;
                            list[ind] = amount;
                            this.setState({selectedFamily: value, listOfCost: list, familyList: familyList});
                          }}
                        />}
                      </TableCell>
                      <TableCell align="right" style={{ width: "10%", paddingTop:0, paddingBottom: 0, fontFamily: "Lato", fontSize: "15px", color:"rgba(0, 0, 0, 0.6)"}}>
                        <span
                          className="cost-per-month"
                          style={{
                            fontWeight: 700,
                            fontSize: "25px",
                            color: index===this.state.selected?"#fff":"#00c389"
                          }}
                        >
                          ${Number(this.state.listOfCost[index]).toFixed(2)}
                        </span>
                        /MONTH
                      </TableCell>
                      <TableCell align="right" style={{ width: "10%", paddingTop:0, paddingBottom: 0  }}>
    
                        <IconButton onClick={() => window.open(row.link)}>
                          <span  style={{ fontSize: 13, fontWeight: "bold", color: isItemSelected? "#0e234d" : "#009cde", width:100 }}>VIEW DETAILS</span>
                          </IconButton>
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
                </TableContainer>

              </Paper>
              </Box>
            <div style={{paddingTop: 40, paddingLeft: 20, paddingBottom:20}}>
            <ProceedButton disabled>Back</ProceedButton> 
             <ProceedButton style={{marginLeft: 40}} onClick={(e) => this.SubmitPlanRequest(e)}>Select Plan & Proceed</ProceedButton>
            </div>
      </div>
    )
    else return <> <Loader/> </>
  }
}

const mapStateToProps = state => {
  return {
    subId: state.subId
  };
}

export default withStyles(styles)(connect(mapStateToProps)(PlanSelection));

const EnhancedTableToolbar = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
  
    return (
      <>
        <Box>
          <Toolbar
            style={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              backgroundColor: "#162242",
            
              width: "100%",
              zIndex: 10,
            }}
          >
            <Grid row container spacing={1}>
              <Grid item xs={12} sm={12} md={9} lg={9}>
                <Typography
                  style={{ 
                   marginTop: 15,
                  color: "#fff" }}
                  component="div"
                >
                  {props.title}
                </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={2} lg={2}>
                  <IconButton>
                    <h3 style={{ color: "#00c389", margin: 0, fontStyle: "Lato", fontWeight: "700", marginLeft: 13 }}>
                      ${Number(props.costSelected + (props.reEnrollBySC ? 0 :props.enrollFeeSelected)).toFixed(2)}
                    </h3>
                  </IconButton>
                </Grid>
                <Grid item xs={6} sm={6} md={1} lg={1}>
               
                  <IconButton disabled={props.reEnrollBySC} onClick={() => setModalShow(true)}>
                    <img src={Cart}/>
                    <div style={{marginLeft: 15, marginTop: "-7px"}}>
                    <Badge color="secondary" badgeContent={1}/>
                    </div>
                  </IconButton>
          
                </Grid>
            </Grid>
          </Toolbar>
        </Box>
        <ModalPopup
          show={modalShow}
          selectedFamily={props.selectedFamily}
          onHide={() => setModalShow(false)}
          planSelected={props.planSelected}
          costSelected={props.costSelected}
          enrollFeeSelected={props.enrollFeeSelected}
        />
      </>
    );
  };

  const ModalPopup = (props) => {
    return (
      <>
        <Modal
          open={props.show}
          onClose={props.onHide}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className='ModalStyle'>
            <Box>
              {" "}
              <Toolbar
                style={{
                  backgroundColor: "#458586",
                  padding: 0,
                  paddingLeft: 20
                }}
                className="modal-header"
              >
                <Typography
                  style={{
                    flex: "1 1 100%",
                    fontFamily: "Lato",
                    fontSize: "20px",
                    fontWeight: "bold",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "1",
                    letterSpacing: "0.15px",
                    textAlign: "left",
                    color: "#ffff",
                    padding: 0
                  }}
                  variant="b"
                  id="tableTitle"
                  component="div"
                >
                  Review Choices
                </Typography>
                <IconButton onClick={props.onHide}>
                  <CloseIcon style={{color:"#fff"}} />
                </IconButton>
              </Toolbar>
            </Box>
            <Box style={{padding: 20, paddingBottom:50, backgroundColor: "#fff"}}>
              <CommonTable
                planSelected={props.planSelected}
                costSelected={props.costSelected}
                selectedFamily={props.selectedFamily}
                enrollFeeSelected={props.enrollFeeSelected}
              />
            </Box>
          </Box>
        </Modal>
      </>
    );
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    fontStyle: "Lato",
    height: 300,
    bgcolor: "background.paper",
    padding: 0,
    boxShadow: 24,
  };





