import React, { Component } from "react";
//import { Button } from '@material-ui/core';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import {
  TextField,
  Table,
  TableCell,
  Modal,
  TableBody,
  Radio,
  TableHead,
  TableContainer,
  TableRow,
  Button,
  Box,
  Tooltip,
  Paper,
  Toolbar,
  Typography,
  IconButton,
  Grid,
  Badge,
} from "@material-ui/core";
import Loader from "../../../loader";
import { connect } from "react-redux";
import axios from "axios";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import i18n from "../../../../i18next";
import customStyle from "../../../../Assets/CSS/stylesheet_UHS";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import Cart from "../../../../Assets/Images/cart.svg";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker,
} from "@material-ui/pickers";
import CommonDropFamily from "../CommonPopup/CommonDropFamily";
import CommonDropDwn from "../CommonPopup/CommonDropDwn";
import CommonTable from "./CommonTable";
import "../Add-ons/addOnsScreen.css";
import MultiAddons from "./MultiAddons";
import { Select, MenuItem, FormControl } from "@material-ui/core";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: "#fa6446",
  },
  tooltip: {
    backgroundColor: "#fa6446",
    border: "1px solid #dadde9",
    fontSize: "12px",
  },
}));

const useStylesBootstrap_otherQuote = makeStyles((theme) => ({
  arrow: {
    color: "#4a4b57",
  },
  tooltip: {
    backgroundColor: "#4a4b57",
    border: "1px solid #dadde9",
    fontSize: "12px",
  },
}));

const StyledTableRow = withStyles({
  root: {
    backgroundColor: "#F4F4F4",
    "&.Mui-selected": {
      backgroundColor: "#08C289",
    },
    "&.Mui-selected.MuiTableRow-hover": {
      backgroundColor: "#08C289",
    },
  },
})(TableRow);

const ProceedButton = withStyles({
  root: {
    backgroundColor: "#0e234d",
    color: "#ffffff",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#420045",
      boxShadow: "0 4px 8px 0 #eae8db, 0 2px 4px 0 #eae8db",
    },
    "&:focus": {
      outline: "none",
    },
    "&:disabled": {
      backgroundColor: "#BDBDBD",
      color: "#ffffff",
    },
  },
})(Button);

const styles = (theme) => ({
  table: {
    minWidth: 400,
  },
});

class AddonScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientid: sessionStorage.getItem("CLIENT_ID"),
      subId: JSON.parse(localStorage.getItem("CurrentLoginUser")).id,
      addonList: null,
      arrayOfAddonAmount: [],
      listofAddonSelected: [],
      addonSelectedFamily: [],
      selected: null,
      planSelectedAmount: null,
      data: null,
      selecdPlanID: null,
      appFeeAmount: null,
      loaderShow: true,
      coreSelectedFamily: null,
      selectedAddon: "Essential",
      selectedIndex: 0,
      addOns: [
        {
          index: 0,
          label: "Critical Illness",
          key: "Essential",
        },
        {
          index: 1,
          label: "Accidental Death and Dismemberment",
          key: "Guardian",
        },
        {
          index: 2,
          label: "Dental",
          key: "Dental",
        },
      ],
      arrayOfAddonAmountBadge: [],
      listOfCost: [],
    };
  }

  componentDidMount() {
    this.setState({ loaderShow: true });
    let selected = localStorage.getItem("selectedAddon");
    this.selectAddOn(selected || "Essential");

    axios
      .get(
        process.env.REACT_APP_BASE_URL +
          "/enrollment/saveCompletionStatus/" +
          this.state.subId +
          "/" +
          this.props.activeStep
      )
      .then((response) => {
        if (response && response.data.code === 200)
          console.log(response.data.code);
      })
      .catch((error) => console.log(error));

    this.getCorePlanDetails();

    axios
      .get(
        process.env.REACT_APP_BASE_URL +
          "/plan/getAddonList/" +
          `${this.state.clientid}`
      )
      .then((response) => {
        this.setState({ addonList: response.data.response }, () => {
          response.data.response.forEach((addon, indexStep) => {
            axios
              .get(
                process.env.REACT_APP_BASE_URL +
                  "/plan/getAddonData/" +
                  `${this.state.subId}/${this.state.clientid}/${addon}`
              )
              .then((res) => {
                this.setState({ data: res.data.response }, () => {
                  let familyList = [];
                  this.setState({ familyList: familyList }, () => {
                    axios
                      .get(
                        process.env.REACT_APP_BASE_URL +
                          "/plan/getMemberPlan" +
                          `/${this.state.subId}/${addon}`
                      )
                      .then((resp) => {
                        if (resp.data.response) {
                          let arrayOfAddonAmountBadge =
                            this.state.arrayOfAddonAmountBadge;
                          let listofAddonSelected =
                            this.state.listofAddonSelected;
                          let addonSelectedFamily =
                            this.state.addonSelectedFamily;

                          let index = res.data.response.indexOf(
                            res.data.response.filter(
                              (x) => x.planId === resp.data.response.planCode
                            )[0]
                          );
                          let flagRemoveDepentent = res.data.response[
                            index
                          ].categoryAmountList.find(
                            (x) => x.category === resp.data.response.category
                          )
                            ? true
                            : false;

                          arrayOfAddonAmountBadge[indexStep] =
                            flagRemoveDepentent
                              ? resp.data.response.amount
                              : res.data.response[index].categoryAmountList[0]
                                  .amount;
                          listofAddonSelected[indexStep] = resp.data.response
                            .planCode
                            ? resp.data.response.planCode
                            : "";
                          addonSelectedFamily[indexStep] = flagRemoveDepentent
                            ? resp.data.response.category
                            : res.data.response[index].categoryAmountList[0]
                                .category;
                          arrayOfAddonAmountBadge.length = 3;
                          this.setlistofAddonSelected(
                            listofAddonSelected[indexStep]
                          );
                          this.setArrayOfAddonAmountBadge(
                            arrayOfAddonAmountBadge[indexStep],
                            indexStep
                          );
                          this.setaddonSelectedFamily(
                            addonSelectedFamily[indexStep]
                          );
                          this.setState({
                            arrayOfAddonAmountBadge: arrayOfAddonAmountBadge,
                            loaderShow: false,
                          });
                        } else this.setState({ loaderShow: false });
                      });
                  });
                });
              });
          });
        });
      });
  }

  getCorePlanDetails = () => {
    axios
      .get(
        process.env.REACT_APP_BASE_URL +
          "/plan/getMemberPlan" +
          `/${this.state.subId}/Core`
      )
      .then((resp) => {
        if (resp.data.response)
          this.setState({
            planSelectedAmount: resp.data.response.amount,
            appFeeAmount: resp.data.response.appFee,
            selecdPlanID: resp.data.response.planCode,
            coreSelectedFamily: resp.data.response.category,
          });
        else this.props.backStep();
      });
  };

  setTotalAmount = (val, index) => {
    let arrayOfAddonAmount = this.state.arrayOfAddonAmount;
    let arrayOfAddonAmountBadge = this.state.arrayOfAddonAmountBadge;
    arrayOfAddonAmount[index] = val;
    arrayOfAddonAmountBadge[index] = val;
    this.setState({
      arrayOfAddonAmount: arrayOfAddonAmount,
      arrayOfAddonAmountBadge: arrayOfAddonAmountBadge,
    });
  };
  setArrayOfAddonAmountBadge = (val, index) => {
    let arrayOfAddonAmountBadge = this.state.arrayOfAddonAmountBadge;
    arrayOfAddonAmountBadge[index] = val;
    this.setState({ arrayOfAddonAmountBadge: arrayOfAddonAmountBadge });
  };
  setlistofAddonSelected = (val, index) => {
    let listofAddonSelected = this.state.listofAddonSelected;
    listofAddonSelected[index] = val;
    // const filteredArray = [...new Set(listofAddonSelected.filter((item) => item !== undefined))];
    this.setState({ listofAddonSelected: listofAddonSelected });
  };
  setListOfCost = (val) => {
    this.setState({ listOfCost: val });
  };
  setaddonSelectedFamily = (val, index) => {
    let addonSelectedFamily = this.state.addonSelectedFamily;
    addonSelectedFamily[index] = val;
    this.setState({ addonSelectedFamily: addonSelectedFamily });
  };
  SubmitPlanRequest = (e) => {
    if (e.detail === 1) {
      let submitArray = [];
      this.setState({ loaderShow: true });
      // this.state.arrayOfAddonAmountBadge = this.state.arrayOfAddonAmountBadge.filter(function(value) {
      //   return value !== null && value !== undefined;
      // })
      // this.state.addonSelectedFamily = this.state.addonSelectedFamily.filter(function(value) {
      //   return value !== null && value !== undefined;
      // })
      this.state.listofAddonSelected.map((addonSelected, index) => {
        submitArray.push({
          subId: this.state.subId,
          planCode: addonSelected ? addonSelected : null,
          amount: this.state.arrayOfAddonAmountBadge[index]
            ? this.state.arrayOfAddonAmountBadge[index]
            : 0,
          planType: addonSelected
            ? addonSelected.match(/^(\w+)/)[0]
            : this.state.addonList[index],
          appFee: 0,
          category: this.state.addonSelectedFamily[index]
            ? this.state.addonSelectedFamily[index]
            : null,
        });
      });
      axios
        .post(
          process.env.REACT_APP_BASE_URL + "/plan/saveMemberPlanAddon",
          submitArray
        )
        .then((res) => {
          this.props.onClick();
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loaderShow: false });
        });
    }
  };

  selectAddOn = (e) => {
    localStorage.setItem("selectedAddon", e);
    this.setState({
      selectedAddon: e,
      selectedIndex: this.state.addOns.findIndex((item) => item.key === e),
    });
  };

  render() {
    var totalAmountOfAddon = 0;
    this.state.arrayOfAddonAmountBadge.length > 0 &&
      this.state.arrayOfAddonAmountBadge.map((val) => {
        totalAmountOfAddon += val ? val : 0;
      });
    if (this.state.addonList)
      return (
        <div>
          {this.state.loaderShow ? <Loader /> : ""}
          <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <EnhancedTableToolbar
                title={
                  "Select a category, review the add-ons available, compare premiums and click the ADD button."
                }
                planSelected={null}
                planSelectedAmount={this.state.planSelectedAmount}
                appFeeAmount={this.state.appFeeAmount}
                arrayOfAddonAmount={this.state.arrayOfAddonAmount}
                arrayOfAddonAmountBadge={this.state.arrayOfAddonAmountBadge}
                listofAddonSelected={this.state.listofAddonSelected}
                costSelected={
                  totalAmountOfAddon +
                  this.state.planSelectedAmount +
                  this.state.appFeeAmount
                }
                reEnrollBySC={this.props.reEnrollBySC}
                enrollFeeSelected={0}
                addonSelectedFamily={this.state.addonSelectedFamily}
                coreSelecdPlanID={this.state.selecdPlanID}
                coreSelectedFamily={this.state.coreSelectedFamily}
                addonList={this.state.addOns}
                selectAddOn={this.selectAddOn}
                selectedAddon={this.state.selectedAddon}
              />
              <div>
                <TableContainer
                  style={{ minHeight: "fit-content", maxHeight: "38vh" }}
                >
                  <MultiAddons
                    key={this.state.selectedAddon}
                    addon={this.state.selectedAddon}
                    arrayOfAddonAmount={this.state.arrayOfAddonAmount}
                    arrayOfAddonAmountBadge={this.state.arrayOfAddonAmountBadge}
                    listofAddonSelected={this.state.listofAddonSelected}
                    listOfCost={this.state.listOfCost}
                    addonSelectedFamily={this.state.addonSelectedFamily}
                    setArrayOfAddonAmount={(val) =>
                      this.setTotalAmount(val, this.state.selectedIndex)
                    }
                    setArrayOfAddonAmountBadge={(val) =>
                      this.setArrayOfAddonAmountBadge(
                        val,
                        this.state.selectedIndex
                      )
                    }
                    setaddonSelectedFamily={(val) =>
                      this.setaddonSelectedFamily(val, this.state.selectedIndex)
                    }
                    setlistofAddonSelected={(val) =>
                      this.setlistofAddonSelected(val, this.state.selectedIndex)
                    }
                    setListOfCost={(val) => this.setListOfCost(val)}
                    addonList={this.state.addonList}
                    indexOfMultiAddons={this.state.selectedIndex}
                    onClick={() => this.props.onClick()}
                  />
                </TableContainer>
              </div>
            </Paper>
          </Box>
          <div style={{ paddingTop: 40, paddingLeft: 20, paddingBottom: 20 }}>
            <ProceedButton
              disabled={this.state.step === 0 || true}
              onClick={() => this.backScreen()}
            >
              Back
            </ProceedButton>

            <ProceedButton
              style={{ marginLeft: 20 }}
              onClick={(e) => this.SubmitPlanRequest(e)}
            >
              Select add-ons & Proceed
            </ProceedButton>
          </div>
        </div>
      );
    else
      return (
        <>
          <Loader />
        </>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    subId: state.subId,
  };
};

export default withStyles(styles)(connect(mapStateToProps)(AddonScreen));

const EnhancedTableToolbar = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <Box>
        <Toolbar
          style={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            backgroundColor: "#162242",

            width: "100%",
            zIndex: 10,
          }}
        >
          <Grid row container spacing={1}>
            <Grid item xs={12} sm={12} md={7} lg={7}>
              <Typography
                style={{
                  marginTop: 15,
                  color: "#fff",
                }}
                component="div"
              >
                {props.title}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <FormControl
                style={{ m: 1, width: 200, margin: 10 }}
                size="small"
              >
                <Select
                  value={props.selectedAddon}
                  className="addOnDropDown"
                  onChange={(e) => props.selectAddOn(e.target.value)}
                >
                  {props.addonList.map((val, index) => (
                    <MenuItem value={val.key}>{val.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6} md={1} lg={1}>
              <IconButton>
                <h3
                  style={{
                    color: "#00c389",
                    margin: 0,
                    fontStyle: "Lato",
                    fontWeight: "700",
                    marginLeft: 13,
                  }}
                >
                  ${!props.reEnrollBySC ? Number(props.costSelected).toFixed(2):  Number(props.costSelected - props.appFeeAmount).toFixed(2)}
                </h3>
              </IconButton>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={1}
              lg={1}
              style={{ display: "flex", justifyContent: "end" }}
            >
              <IconButton onClick={() => setModalShow(true)}>
                <img src={Cart} />
                <div style={{ marginLeft: 15, marginTop: "-7px" }}>
                  <Badge
                    color="secondary"
                    badgeContent={
                      1 +
                      props.arrayOfAddonAmountBadge.filter((x) => x !== 0)
                        .length
                    }
                  />
                </div>
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </Box>
      <ModalPopup
        show={modalShow}
        reEnrollBySC={props.reEnrollBySC}
        onHide={() => setModalShow(false)}
        planSelectedAmount={props.planSelectedAmount}
        appFeeAmount={props.appFeeAmount}
        planSelected={props.planSelected ? props.planSelected : ""}
        arrayOfAddonAmount={props.arrayOfAddonAmountBadge}
        listofAddonSelected={props.listofAddonSelected}
        costSelected={props.costSelected}
        coreSelecdPlanID={props.coreSelecdPlanID}
        enrollFeeSelected={props.enrollFeeSelected}
        coreSelectedFamily={props.coreSelectedFamily}
        addonSelectedFamily={props.addonSelectedFamily}
      />
    </>
  );
};

const ModalPopup = (props) => {
  return (
    <>
      <Modal
        open={props.show}
        onClose={props.onHide}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="ModalStyle">
          <Box>
            {" "}
            <Toolbar
              style={{
                backgroundColor: "#458586",
                padding: 0,
                paddingLeft: 20,
              }}
              className="modal-header"
            >
              <Typography
                style={{
                  flex: "1 1 100%",
                  fontFamily: "Lato",
                  fontSize: "20px",
                  fontWeight: "bold",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "1",
                  letterSpacing: "0.15px",
                  textAlign: "left",
                  color: "#ffff",
                  padding: 0,
                }}
                variant="b"
                id="tableTitle"
                component="div"
              >
                Review Choices
              </Typography>
              <IconButton onClick={props.onHide}>
                <CloseIcon style={{ color: "#fff" }} />
              </IconButton>
            </Toolbar>
          </Box>
          <Box
            style={{ padding: 20, paddingBottom: 50, backgroundColor: "#fff" }}
          >
            <CommonTable
               reEnrollBySC={props.reEnrollBySC}
              planSelected={props.planSelected}
              planSelectedAmount={props.planSelectedAmount}
              arrayOfAddonAmount={props.arrayOfAddonAmount}
              listofAddonSelected={props.listofAddonSelected}
              appFeeAmount={props.appFeeAmount}
              costSelected={props.costSelected}
              enrollFeeSelected={props.enrollFeeSelected}
              coreSelecdPlanID={props.coreSelecdPlanID}
              coreSelectedFamily={props.coreSelectedFamily}
              addonSelectedFamily={props.addonSelectedFamily}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
};
